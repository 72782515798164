import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import filter from "../assets/img/products/filter.png";
import flanges from "../assets/img/products/flanges.jpeg";
import gasket from "../assets/img/products/gasket.webp";
import shippingpump from "../assets/img/products/shippingpump.webp";
import industrialfilter from "../assets/img/products/industrialfilter.jpg";
import solarlight from "../assets/img/products/sloarlight.avif";
import wellhead from "../assets/img/products/wellhead.jpg";

const Products = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    {
      name: "Solar Lighting Systems ",
      image: solarlight,
    },
    { name: "Flanges", image: flanges },
    { name: "Gasket", image: gasket },
    { name: "Wellhead", image: wellhead },
    { name: "Shipping Pump", image: shippingpump },
    { name: "Industrial Filter", image: industrialfilter },
  ];

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Products</h2>
            <p></p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
