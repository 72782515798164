import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import bins from "../assets/img/stationary/bins.png";
import cabin from "../assets/img/stationary/cabin.jpg";
import compressor from "../assets/img/stationary/compressor.jpg";
import container from "../assets/img/stationary/container.jpg";
import driller from "../assets/img/stationary/driller.jpg";
import fractank from "../assets/img/stationary/fractank.jpg";
import vfractank from "../assets/img/stationary/fractank.png";
import generator from "../assets/img/stationary/genrator.jpg";
import hoses from "../assets/img/stationary/hoses.jpg";
import hydro from "../assets/img/stationary/hydro.png";
import lowtrailer from "../assets/img/stationary/lowtrailer.jpg";
import vaccum from "../assets/img/stationary/vaccumexcavator.jpg";
import storagetank from "../assets/img/stationary/storagetank.jpg";
import sparkarrestor from "../assets/img/stationary/sparkarrestor.png";
import wastebin from "../assets/img/stationary/wastebin.png";

const Products = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    { name: "Hydro Jetting Machine", image: hydro },
    { name: "Storage Tanks", image: storagetank },
    { name: "Electrical Generator", image: generator },
    { name: "Industrial Air Compressor", image: compressor },
    { name: "Porta Cabins", image: cabin },
    { name: "Industrial Hoses", image: hoses },
    { name: "Low Bed Trailer", image: lowtrailer },
    { name: "Frac Tank", image: fractank },
    { name: "Containers", image: container },
    { name: "Vertical Frac Tanks", image: vfractank },
    { name: "Vacuum Excavator", image: vaccum },
    { name: "General Waste Bin", image: wastebin },
    { name: "Horizontal Drilling Machine", image: driller },
    { name: "General Waster Bin", image: bins },
    { name: "Spark Areestor", image: sparkarrestor },
  ];

  return (
    <>
      <Helmet>
        <title>Almourid | Stationary Equipments</title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Stationary Equipments</h2>
            <p></p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
