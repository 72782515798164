import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/clean-block.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import "../assets/css/Features-Blue.css";
import "../assets/css/preloader.css";
import "../assets/css/Projects-Horizontal.css";
import "../assets/css/Team-Boxed.css";
import "../assets/css/vanilla-zoom.min.css";
import alpha from "../assets/img/logos/alpha.png";
import DKC from "../assets/img/logos/DKC.jpg";
import erre from "../assets/img/logos/erre.png";
import swiften from "../assets/img/logos/swiften.jpg";
import ges from "../assets/img/logos/ges.jpeg";
import greenovative from "../assets/img/logos/greenovative.jpeg";
import imaqt from "../assets/img/logos/imaqt.png";
import maun from "../assets/img/logos/logo-maus.png";
import osna from "../assets/img/logos/osna.jpg";
import phtoelectric from "../assets/img/logos/photoelectric.png";
import fdc from "../assets/img/logos/fdc.jpg";
import swift from "../assets/img/logos/swift.webp";
import preloader from "../assets/js/preloader";
import Counter from "../components/Counter";
const logosList = [
  { src: alpha, alt: "Alpha" },
  { src: DKC, alt: "DKC" },
  { src: erre, alt: "Erre" },
  { src: swiften, alt: "Swiften" },
  { src: ges, alt: "GES" },
  { src: greenovative, alt: "Greenovative" },
  { src: imaqt, alt: "IMAQT" },
  { src: maun, alt: "Maun" },
  { src: osna, alt: "OSNA" },
  { src: phtoelectric, alt: "Photoelectric" },
  { src: fdc, alt: "FDC" },
  { src: swift, alt: "Swift" },
];

const Home = () => {


  return (
    <>
      <Helmet>
        <title>Home Page</title>
      </Helmet>
      <body>
        <div className="loader-wrapper">
          <div className="loader"></div>
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div>
      </body>
      <section className="clean-block stats">
        <section
          className="clean-block clean-hero"
          style={{
            color: "rgba(255, 162, 0, 0.85)",
          }}
        >
          <section className="counters">
            <div className="container">
              <div className="box">
                <Counter target={5000} title="Projects Completed" />
              </div>
              <div className="box">
                <Counter target={5000} title="Projects Completed" />
              </div>
              <div className="box">
                <Counter target={5000} title="Projects Completed" />
              </div>
              <hr />
            </div>
          </section>
        </section>
        <section className="clean-block partner">
          <h2 className="text-info">Our Partners</h2>
          <div className="logos-wrapper">
            <div className="logos">
              <div className="logo-set">
                {logosList.map((logo, index) => (
                  <img
                    key={index}
                    src={logo.src}
                    alt={logo.alt}
                    className="img-fluid"
                  />
                ))}
              </div>
              <div className="logo-set">
                {logosList.map((logo, index) => (
                  <img
                    key={index + logosList.length} // Ensure unique keys for duplicated logos
                    src={logo.src}
                    alt={logo.alt}
                    className="img-fluid"
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 offset-md-0">
              <hr className="my-4" style={{ borderTop: '0.5px solid #6c757d' }} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Almourid</h2>
            <p>almourid for oil services is a striving company.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-5 feature-box">
              <i className="icon-star icon"></i>
              <h4>Mission</h4>
              <p>
                We are a result oriented oil and gas company that builds value
                for all its stakeholders through its polyees by creating an
                atmosphere of optimisim, teamwork, creativity, resourcefulness
                and by operating in an open and ethical manner.
              </p>
            </div>
            <div className="col-md-5 feature-box">
              <i className="icon-pencil icon"></i>
              <h4>Late His Majesty Sultan Qaboos Bin Said</h4>
              <p>
                "Education and training human is a laborious process. Yet, it is
                a necessary process. We shall, for our part, spare no effort to
                provide oppertunities for the training of Omanis at all levels
                of education."
              </p>
            </div>
            <div className="col-md-5 feature-box">
              <i className="icon-screen-smartphone icon"></i>
              <h4>SMEs</h4>
              <p>
                "SMEs are very improtant, especially entrepreneurships based on
                innovation, artificial intelligence and advanced technologies,
                and training and empowerment of young people to take advantage
                of such oppertunities. To be a basic building block in the
                national economic system, our government will work to follow up
                the progress in these aspects.." His Majesty Sultan Haitham bin
                Tariq Al said.
              </p>
            </div>
            <div className="col-md-5 feature-box">
              <i className="icon-refresh icon"></i>
              <h4>Vision</h4>
              <p>
                We strive hard every day to evolve as the most distinguished
                branch of local service provider in our chosen fields and to
                contribute to the sustained development of the nation with a
                positve impact.
              </p>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Message from the desk of CEO</h2>
          </div>
          <div className="row justify-content-center">
            <div>
              <i className="icon-star icon"></i>
              <p>
                AL MOURID FOR OIL SERVICES LLC, Head quartered in Manah and with
                branch office in Muscat, we are a company with strong commitment
                to the nation & the society, and draws immense strength from the
                positive coexistence. As a fast developing organization, we take
                calculative efforts to meet the demand in the growing Oman
                market for critical services & also leverage our ability to
                extend solutions to the wider Middle East region. We envisage
                that the growth of employees, the company & the nation are
                synergetic. Through the chosen areas of operation, our
                organization progress on the pathof long term business growth by
                developing sustainable partnerships, researching for innovative
                technology providers and pioneering green energy applications
                that protect the environment. We strive at best leves to
                contribute to the society to make life easier and smarter. As a
                responsible organization, we are committed to Provide its best
                support to the local community in terms of giving opportunity to
                suitable candidates available for its operating area as well as
                provide skill enhancement training to Omani youth to develop
                them in the field of the company's operations.
              </p>

              <p>Sincerely Yours,</p>
              <p>Salim Al Junaibi</p>
              <p>Chief Executive Officer</p>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </>
  );
};

export default Home;
