import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Navbar.css";
import "../assets/css/preloader.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import logo from "../assets/img/logo.png";

const Navbar = () => {
  const [hovered, setHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = () => {
    if (isMobile) {
      setHovered(false);
    } else {
      setTimeout(() => setHovered(true), 500);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <nav className="navbar navbar-light navbar-expand-lg fixed-top bg-white clean-navbar">
      <div className="container">
        <Link className="navbar-brand logo" to="/">
          <img
            src={logo}
            alt="Logo"
            style={{ height: "50px", marginRight: "20px" }}
          />
          Almourid For Oil Services
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navcol-1"
          aria-controls="navcol-1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navcol-1">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" aria-current="page">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/products">
                Products
              </Link>
            </li>
            <li
              className={`nav-item dropdown ${hovered ? "show" : ""}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link
                className="nav-link dropdown-toggle"
                to="/services"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="/services/equipments">
                  Equipment Rental
                </Link>
                <Link className="dropdown-item" to="/services/trucks">
                  Truck Rental
                </Link>
                <Link className="dropdown-item" to="/services/vehicles">
                  Vehicle Rental
                </Link>
                <Link className="dropdown-item" to="/services/stationary">
                  Stationary Equipment Rental
                </Link>
                <Link className="dropdown-item" to="/services/manpower">
                  Manpower Supply
                </Link>
                <Link className="dropdown-item" to="/services/customized">
                  Customized Services
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
