import React from "react";
import { Helmet } from "react-helmet";
import "../assets/bootstrap/css/bootstrap.min.css";
import "../assets/css/clean-block.css";
import logo from "../assets/img/oildigger.jpg";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Almourid | About</title>
      </Helmet>
      <section className="clean-block about-us">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">About Us</h2>
            <p>Learn more about our company and team.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-8">
              <div className="card clean-card text-center">
                <img
                  className="card-img-top w-100 d-block"
                  src={logo}
                  alt="About Us"
                />
                <div className="card-body info">
                  <h4 className="card-title">Our Company</h4>
                  <p className="card-text">
                    As a new generation enterprise in the Sultanate of Oman, 'AL
                    MOURID FOR OIL SERVICES LLC' offers a wide range of
                    specialised products & value added services to strengthen
                    various cogs in the nation's wheel of development. Run by
                    professionals with vast experience in Oil & Gas sector, we
                    aim to render high quality & reliability in performance
                    delivery. From the humble beginning in the year 2000 as a
                    logistics service company, Al Mourid has grown through
                    diversification. We preferentially choose the growth
                    oriented sectors to operate on. Today, we are in the select
                    list of Petroleum Development Oman as an LCC (local
                    community contractor) and are registered with JSRS & all
                    major clients in Oman. Apart from green-field & brown-field
                    supplies, our core services span through Pipeline/Plant
                    Inspection & Integrity Analysis, Power System Management,
                    Asset Management, Industrial IT Solutions, Operation &
                    Maintenance, Well Services, Rotating Equipment Balancing
                    Services & Replacement Spareparts, e-Learning Modules, Skill
                    Enhancement Training, Project Management, Hiring of skilled
                    manpower, Rental of heavy equipments & vehicles, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
