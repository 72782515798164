import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import boxtruck from "../assets/img/boxtruck.jpg";
import canter from "../assets/img/Canter.jpg";
import dump from "../assets/img/Dump.jpg";
import fueltrans from "../assets/img/FuelTrans.jpg";
import hiroofamb from "../assets/img/HiroofAmb.jpg";
import landcruiser from "../assets/img/Landcruiser.jpg";
import opal from "../assets/img/opal.png";
import pickup from "../assets/img/Pickup.jpg";
import prime from "../assets/img/Prime.jpg";
import sweeper from "../assets/img/RoadSweeper.jpg";
import tipper from "../assets/img/Tipper.png";
import vaccum from "../assets/img/vaccumTanker.png";
import bus25 from "../assets/img/Bus25.jpg";
import bus60 from "../assets/img/Bus60.jpg";
import concrete from "../assets/img/concrete.png";
import cement from "../assets/img/cement.png";
const Products = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    { name: "Box Ambulance", image: boxtruck },
    { name: "Land Cruiser", image: landcruiser },
    { name: "Hiroof Ambulance", image: hiroofamb },
    { name: "Bus 25-Seater", image: bus25 },
    { name: "Road Sweeper", image: sweeper },
    { name: "Pickup Double Cabin", image: pickup },
    { name: "Canter Double Cabin", image: canter },
    { name: "Mini Self loading Concrete Mixer", image: concrete },
    { name: "Bus 60-Seater", image: bus60 },
    { name: "Cement Mixer Truck", image: cement },
    { name: "Vacuum Tanker (8000 Gallon)", image: vaccum },
    { name: "Prime Mover with Hiab 4x4", image: prime },
    { name: "Fuel Transportation Truck", image: fueltrans },
    { name: "Dump Truck", image: dump },
    { name: "Tipper 45 cm", image: tipper },
  ];

  return (
    <>
      <Helmet>
        <title>Almourid | Vehicles</title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Rental Vehicles</h2>
            <p></p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
