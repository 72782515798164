import React, { useEffect, useState } from "react";
import "../assets/css/clean-block.css";

const Counter = ({ target, title }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = target;
    const duration = 1000;
    const increment = end / (duration / 100);

    const counter = setInterval(() => {
      start += increment;
      setCount(Math.min(Math.ceil(start), end));

      if (start >= end) {
        clearInterval(counter);
      }
    }, 100);

    return () => clearInterval(counter);
  }, [target]);

  return (
    <div>
      <div className="counter">{count}</div>
      <h3>{title}</h3>
    </div>
  );
};

export default Counter;
