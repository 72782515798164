import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import electrical from "../assets/img/electrical.webp";
import mechanical from "../assets/img/mechanical.jpeg";
import civil from "../assets/img/civil.avif";
import operator from "../assets/img/operator.jpg";
import helper from "../assets/img/helper.webp";


const Manpower = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    { name: "Civil Engineer", image: civil },
    { name: "Mechanical Engineer", image: mechanical },
    { name: "Electrical Engineer (CEP & AEP)", image: electrical },
    { name: "Operator", image: operator },
    { name: "Helper", image: helper },
  ];

  return (
    <>
      <Helmet>
        <title>Almourid | Manpower</title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Manpower</h2>
            <p></p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Manpower;
