import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import cabin from "../assets/img/stationary/cabin.jpg";
import container from "../assets/img/stationary/container.jpg";
import boxtruck from "../assets/img/vehicles/boxtruck.jpg";
import fractank from "../assets/img/stationary/fractank.jpg";


const Customized = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    { name: "Custom box ambulance", image: boxtruck },
    { name: "custom cabins", image: cabin },
    { name: "custom containers", image: container },
    { name: "custom other", image: fractank },
  ];

  return (
    <>
      <Helmet>
        <title>Almourid | Customize </title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Customized Order</h2>
            <p> we offer various customized orders for our clients</p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Customized;
