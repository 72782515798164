import React from 'react';
import '../assets/css/ContactUs.css';


const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h1 className="contact-title">Contact Us</h1>
      <p className="contact-text">
        If you have any questions or need further information, feel free to reach out to us:
      </p>

      <div className="contact-info">
        <div className="contact-item">
          <h3>Phone</h3>
          <p><a href="tel:+96871773813">+968 7177 3813</a></p>
        </div>
        <div className="contact-item">
          <h3>Email</h3>
          <a href="mailto:info@almourid.com">info@almourid.com</a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
