import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import bitumentanker from "../assets/img/trucks/bitumentanker.jpg";
import bluetanker from "../assets/img/trucks/bluetanker.jpg";
import brachish from "../assets/img/trucks/brackish.jpg";
import buckettruck from "../assets/img/trucks/buckettruck.jpg";
import firetruck from "../assets/img/trucks/firetruck.jpg";
import hiab from "../assets/img/trucks/hiab.jpg";
import oiltanker from "../assets/img/trucks/oiltanker.jpg";
import skiptruck from "../assets/img/trucks/skiptruck.jpg";
import supersucker from "../assets/img/trucks/supersucker.jpg";
import trailer from "../assets/img/trucks/trailer.jpg";
import chemicaltrans from "../assets/img/trucks/chemicaltrans.jpg";
import wastetruck from "../assets/img/trucks/wastetruck.jpg";
import yellowtanker from "../assets/img/trucks/yellowtanker.jpg";
import diesel from "../assets/img/trucks/dieseltanker.jpg";

const Products = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    { name: "Blue & Green Tankers", image: bluetanker },
    { name: "Diesel Tanker", image: diesel },
    { name: "Brackish Water", image: brachish },
    { name: "Oil/Chemical Vacuum Tanker", image: oiltanker },
    { name: "Trailer", image: trailer },
    { name: "Bucket Truck", image: buckettruck },
    { name: "Super Sucker - Wet & Dry", image: supersucker },
    { name: "Hiab 4x4", image: hiab },
    { name: "Yellow tanker", image: yellowtanker },
    { name: "Fire Truck", image: firetruck },
    { name: "Chemical Transport Truck", image: chemicaltrans },
    { name: "Skip Truck", image: skiptruck },
    { name: "Bitumen Tanker", image: bitumentanker },
    { name: "Waste Truck", image: wastetruck },
  ];

  return (
    <>
      <Helmet>
        <title>Trucks</title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Almourid | Trucks</h2>
            <p></p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
