import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/css/clean-block.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Stationary from "./pages/Stationary";
import Trucks from "./pages/Trucks";
import Equipments from "./pages/Equipments";
import Vehicles from "./pages/Vehicles";
import Manpower from "./pages/Manpower";
import Customized from "./pages/Customized";
import About from "./pages/About";
import Contact from "./pages/Contact";
import "./assets/css/clean-block.css";
import "./assets/css/Features-Blue.css";
import "./assets/css/preloader.css";
import "./assets/css/Projects-Horizontal.css";
import "./assets/css/Team-Boxed.css";
import "./assets/css/vanilla-zoom.min.css";
import preloader from "./assets/js/preloader";
const App = () => {
  return (
    <Router>
      <div>
        <div>
          <Navbar />

          <Routes>
            <Route index element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<services />} />
            <Route path="/services/trucks" element={<Trucks />} />
            <Route path="/services/stationary" element={<Stationary />} />
            <Route path="/services/Vehicles" element={<Vehicles />} />
            <Route path="/services/Equipments" element={<Equipments />} />
            <Route path="/services/manpower" element={<Manpower />} />
            <Route path="/services/customized" element={<Customized />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
          </Routes>

          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
