import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/products.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import bobcat from "../assets/img/equipments/bobcat.jpg";
import boomlaoder from "../assets/img/equipments/boomlaoder.jpg";
import crane from "../assets/img/equipments/crane.jpg";
import compactingroller from "../assets/img/equipments/compactingroller.jpg";
import dozer from "../assets/img/equipments/dozer.jpg";
import forklift from "../assets/img/equipments/forklift.png";
import forklifts from "../assets/img/equipments/forklifts.jpg";
import excavator from "../assets/img/equipments/excavator.jpg";
import eforklift from "../assets/img/equipments/eforklift.jpg";
import jcb from "../assets/img/equipments/jcb.jpg";
import grader from "../assets/img/equipments/grader.png";
import manlift from "../assets/img/equipments/manlift.jpg";
import shovel from "../assets/img/equipments/shovel.jpg";
import wheelloader from "../assets/img/equipments/wheelloader.jpg";

const Products = () => {
  const productRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    productRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.opacity = 1;
        entry.target.style.transform = "translateY(0)";
      }
    });
  };

  const handleQuoteClick = (productName) => {
    const subject = `Enquiry about ${productName}`;
    window.location.href = `mailto:info@almourid.net?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // Array of products with their details
  const products = [
    { name: "Shovel", image: shovel },
    { name: "Boom Loader", image: boomlaoder },
    { name: "Dozer", image: dozer },
    { name: "Excavator", image: excavator },
    { name: "Mobile Cranes", image: crane },
    { name: "JCB", image: jcb },
    { name: "Bobcat", image: bobcat },
    { name: "Forklift 4x4", image: forklift },
    { name: "Forklift", image: forklifts },
    { name: "Compacting Roller", image: compactingroller },
    { name: "wheel Loader with Fork", image: wheelloader },
    { name: "Manlift", image: manlift },
    { name: "Electric Forklift 3-5 Tons", image: eforklift },
    { name: "Grader Machine", image: grader },
  ];

  return (
    <>
      <Helmet>
        <title>Almourid | Equipments</title>
      </Helmet>
      <section className="products">
        <div className="container">
          <div className="text">
            <h2>Equipments</h2>
            <p></p>
            <div className="row">
              {products.map((product, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    ref={(el) => (productRefs.current[index] = el)}
                    className="product-item"
                  >
                    <div className="product-content">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="img-fluid"
                      />
                      <div className="product-name">{product.name}</div>
                      <button
                        className="btn btn-outline-light btn-lg btn-quote"
                        onClick={() => handleQuoteClick(product.name)}
                      >
                        Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
